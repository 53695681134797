import {Container, Row, Col} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook
} from "@fortawesome/free-brands-svg-icons";


const Footer = () => {

    return(
        <footer className='site-footer'>
            <Container>
                <Row>
                    <Col xs={{ size: 4, offset: 1 }} sm='2'>
                        <ul className='list-unstyled'>
                            <li>
                                <Link style={{ color: '#FFF',fontFamily: 'Philosopher' }} to='/'>Home</Link>
                            </li>
                            <li>
                                <Link style={{ color: '#FFF', fontFamily: 'Philosopher' }} to='/about'>About</Link>
                            </li>
                            <li>
                                <Link style={{ color: '#FFF',fontFamily: 'Philosopher' }} to='/contact'>Contact</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col xs='4' sm='' className='text-center'>
                        <a href="https://www.facebook.com/bharatanatyam.indian.classical.dance.tulsa.OK.USA/"
                        className="facebook social">
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                        </a>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <address className="text-white">
                            1000 N Juniper Pl,
                            <br />
                            Broken Arrow, OK 74012
                            <br /><br />
                            Ph: (918) 409-4344
                       </address>
                </Col>
                <div className='text-center text-white'>
                    <p>© Anand Natyalayas 2025</p>
                </div>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;