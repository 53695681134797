import { useState } from 'react';
import { 
    Navbar, 
    NavbarBrand, 
    Collapse, 
    NavbarToggler, 
    Nav, 
    NavItem, 
    UncontrolledDropdown, 
    DropdownToggle, 
    DropdownMenu, 
    DropdownItem, 
  } from 'reactstrap';
import { NavLink} from 'react-router-dom';
import AnandNatyalayasLogo from '../app/assets/images/logo.jpg';
import DonateButton from './DonateButton';

const Header =()=> {
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);
     

    return(
         
        <Navbar dark sticky='top' expand={'lg'}>
            <NavbarBrand className='ms-2' href='/'>
                <img src = {AnandNatyalayasLogo} alt='Bharathanatyam Dance School Logo' className='float-start'/>
                <h1 className='mt-1 float-start'>Anand Natyalayas</h1>
                <p className='p-title float-start'>School for Indian Classical Dance and Bollywood Dance</p>
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar}/>
            <Collapse isOpen = {!collapsed} navbar>
                <Nav className='ms-auto' navbar>
                    <NavItem>
                        <NavLink style={{fontWeight: 'bold',fontFamily: 'Philosopher',fontSize: '20px'}} onClick = {!collapsed} className= 'nav-link' to ='/' >
                            Home
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{fontWeight: 'bold',fontFamily: 'Philosopher',fontSize: '20px'}} onClick = {!collapsed} className= 'nav-link' to ='/meettheguru' >
                            Meet The Guru
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{fontWeight: 'bold',fontFamily: 'Philosopher',fontSize: '20px'}} onClick = {!collapsed}  className= 'nav-link' to ='/about' >
                            About
                        </NavLink>
                    </NavItem>
                    <NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle  style={{fontWeight: 'bold',fontFamily: 'Philosopher',fontSize: '20px'}} id="dropdownMenuButton"nav caret>
                        Shows
                        </DropdownToggle>
                        <DropdownMenu id="dropdownMenuButton1">
                            <li>
                            <DropdownItem style={{color:'red', fontWeight: 'bold',fontFamily: 'Philosopher',fontSize: '20px'}} href='/summershow'>Summer Shows</DropdownItem>
                            </li>
                            <li>
                            <DropdownItem style={{color:'red', fontWeight: 'bold',fontFamily: 'Philosopher',fontSize: '20px'}} href='/arangetram'>Arangetrams</DropdownItem>
                            </li>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{fontWeight: 'bold',fontFamily: 'Philosopher',fontSize: '20px'}} onClick = {!collapsed} className= 'nav-link' to ='/gallery' >
                            Gallery
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink  style={{fontWeight: 'bold',fontFamily: 'Philosopher',fontSize: '20px'}} onClick = {!collapsed} className= 'nav-link' to ='/contact' >
                            Contact
                        </NavLink>
                    </NavItem>
                    <NavItem>  
                        <DonateButton />
                    </NavItem>
                </Nav>
            </Collapse>
       </Navbar>  
    );
};

export default Header;