import {Container, Card, CardTitle, CardText, CardBody} from 'reactstrap';
import Dharshini from '../app/assets/images/dharshini.jpg';
import Teacher from '../app/assets/images/Dharshinimala.jpg';
import AssistantTeacher from '../app/assets/images/preethi.jpg';
import Guru1 from '../app/assets/images/Guru.jpg';

const MeetTheGuruPage = () => {
    return (
        <Container>
            <div className='row-content'>
                <img width= '100%' src = {Dharshini} alt = 'dharshini' />
            </div>
            <div className='row-content'>
                <h2 className='text-center text-white'>Meet The Guru</h2>
                <img className= 'center'src= {Teacher} alt='teacher' />
            </div>
                <Card> 
                    <CardTitle className='text-center mt-3'>Smt. DHARSHINI MALA ANAND MOORTHY </CardTitle>
                    <CardBody>
                        <CardText>
                            Dharshini started her initial Bharatanatyam training at the tender age of 6 from Guru Vasantha Mallika of Kalakshetra, Dharshini  continued her training under a galaxy of eminent Bharatanatyam Gurus including Kalaimamani. Smt Vyjayanthi Mala Selvaratnam(senior most Disciple of Guru Adayar Lakshman) and the renowned Guru Padmabhushan Dr.Saroja Vaidyanathan an SNA RATNA AWARDEE.
                        </CardText>
                        <CardText>
                            Dharshini was a recipient of the Indian Council for Cultural Relations (ICCR) Scholarship, which she was awarded to pursue BharataNatyam under the tutelage of accomplished Guru Padmashri Dr.Saroja Vaidyanathan, Founder President of Ganesa Natyalaya, New Delhi. Dharshini has been also one of the Performing Artists in her Guru Dr. Saroja Vaidyanathan’s group and traveled all over India, participating in Dance performances and Lecture Demonstrations along with her Guru.
                        </CardText>
                        <CardText>
                            Ganesa Natyalaya, has given her the title of “KALASHREE” and a Higher Diploma certificate for completing the three-year course enthusiastically. During this three year course she has pursued her B.A. (HONS) in Bharatanatyam successfully in Indira Kala Sangit Vishwavidyalaya, University of Music, Dance, and Folk Music & Arts, Khairagarh, India, which Ganesa Natyalaya is affiliated to.
                        </CardText>
                        <CardText>
                            Anand Natyalayas annual performances have been thematic, and a delight to watch . Dharshini has synthesized the knowledge she received from her Gurus to imprint Bharatanatyam with her personal vision of the dance. In her dance presentations Dharshini skillfully weaves abstract notions of Joy, Beauty, Values, Aspirations, Myth and Spirituality.  Dharshini brings to her dance a rare sense of devotion and dedication, which leaves the audience with a sense of spiritual fulfillment.  She endeavors to create an awareness and love for Bharatanatyam in the current generation.
                    </CardText>
                    </CardBody>
                </Card>
            <div className='row-content'>
                <h2 className='text-center text-white'>Assistant Guru</h2>
                <img className= 'center'src= {AssistantTeacher} alt='assistant teacher' />
            </div>
                <Card>
                    <CardTitle className='text-center mt-3'>Smt.PREETHI MANJUNATH </CardTitle>
                    <CardBody>
                       <CardText>
                            Preethi Manjunath has been passionately expressing herself through dance since the age of 6. Over the years, she has blossomed into a dancer of rare merit and dedication, showcasing precise footwork, clear angashuddam, and nuanced abhinaya. Endowed with a fine, lissom visage, she embodies infinite shades of emotions while maintaining a fine balance of nritta and laya. Preethi has been trained under the guidance of the eminent Guru Smt. Dr. Malini Ravishankar, Founder-Director of Lasyavardhana Performing Arts, Bangalore.
                       </CardText>
                       <CardText>
                            As an ardent student in the Gurukula of Smt. Dr. Malini, Preethi has completed junior, senior, and vidwat grade examinations conducted by the KSEEB with distinction. She has also received training in the fine aspects of Natya Shastra under Guru Smt. Shama Krishna. From 2018 to 2020, Preethi served as a Bharatanatyam instructor at Sangeetha Shala in Bangalore, sharing her expertise and passion with aspiring dancers. 
                       </CardText>
                    </CardBody>
                </Card>
            <div className='row-content'>
                <img className= 'center'src={Guru1} alt='guru'/>
            </div>
                <Card>
                    <CardTitle className='text-center mt-3'>Dharshini's Guru, Dr.Saroja Vaidyanathan</CardTitle>
                    <CardBody>
                        <CardText>
                            Padmabhushan Guru Saroja Vaidyanathan trained under the accomplished Guru Lalitha of Saraswati Gana Nilayam from a very tender age of seven years. She is the third generation (parampara) disciple of the famous Guru Kattumannar Muthukumaran Pillai. She has also undergone training in Carnatic music under Prof.P.Sambamoorthy at the Madras University.
                        </CardText>
                        <CardText>
                            Saroja has been one of India's prominent BharataNatyam exponents earning her fame as an intelligent and innovative choreographer. Her dance repertoire has revolved around subjects ranging from mythology to current social issues that have been widely acclaimed by the audience and the critics alike. She has widely traveled within India as well as abroad performing and giving lecture demonstrations at various prestigious venues. She has also choreographed over a score of enthralling ballets based on mythology as well as social themes that have been appreciated and repeatedly sponsored.
                        </CardText>
                        <CardText>
                            In 1974, Guru Saroja started Ganesa Natyalaya to impart training to young students from all over the world. To date, the Natyalaya has more than 300 arangetrams. Ganesa Natyalaya is where Dharshini spent her formative years training rigorously on the basics of Bharatanatyam under her Guru. 
                        </CardText>
                        <CardText>
                            AWARDS
                        </CardText>
                        <CardText>
                            Saroja was conferred the Padma Shri in 2002 and the Padma Bhushan in 2013 by the Government of India.She is also the recipient of the Sahitya Kala Parishad Samman of the Government of Delhi, the Kalaimamani title bestowed by the Tamil Nadu Eyal Isai Nataka Manram and the Sangeet Natak Akademi Award. She was conferred the title of 'Bharata Kalai Sudar' in 2006. In 2023 she was awarded SNA RATNA, which is a most prestigious award. 
                        </CardText>
                    </CardBody>
                </Card>
                <div>
                </div>
                <Card>
                    <CardTitle className='text-center mt-3'>Dharshini's Guru, Smt. Vyjayanthimala Selvaratnam </CardTitle>
                    <CardBody>
                        <CardText>
                            Smt. Vyjayanthimala Selvaratnam earned her "Sangeetha Vidvan" award in 1972, from the Chennai Central Carnatic Music College and  her Bharata Natya graduation from the Academy of Fine Arts,  Adayar, Chennai, through the world renowned Guru Adayar Padma Bushan shri K.  Lakshman.
                        </CardText>
                        <CardText>                           
                            She persuaded her Arangetram in Jaffna, Weerasingham Auditorium, under the distinguished presence of Dr. Subramaniyam with Adayar Padma Bhushan Shri K. Lakshman's Nattuvangam. She has performed at various cultural programs in India with renowned Artists like Selvi. Jayalalitha, Sri. Dhananjayan and Smt. Yamini Krishnamoorthy. 
                        </CardText>
                        <CardText>
                            Her thoughts to share with others,  her knowledge of this great form of Art, speaks well of her noble desire and dedication with a view to give her wider opportunity to young artists in and around Kandy, Sri Lanka.  She devotes her time to this noble art at the Institute of Fine Arts , Central Province Hindu Association and SIDA, District Education Program for teachers.  
                        </CardText>
                    </CardBody>
                </Card>
       </Container>
    );
};
    
    

export default MeetTheGuruPage;